<!-- 我的优惠券 -->
<template>
    <div class="container">
        <titleText title="我的优惠券"/>
        <ul class="flex flex-wrap">
            <li class="coupon-item flex align-c color-fff" v-for="item in couponList" :key="item.id">
                <div v-if="item.type == 2" class="coupon-item-l font-24">{{item.amount.toFixed(1)}}折</div>
                <div v-else class="coupon-item-l font-24">￥{{item.amount}}</div>
                <div class="flex-column flex-1 justify-c">
                    <p class="font-14">{{item.ticketName}}</p>
                    <p class="font-12 mt-10">
                        <span v-if="item.type == 1 && item.limit">{{item.limit}}张，</span>
                        <span>{{item.condition ? "满" + item.condition + "元可用" : "无使用门槛"}}</span>
                    </p>
                    <p class="font-12 mt-10">有效期截至：{{item.endTime | dateFormat('YYYY-MM-DD')}}</p>
                </div>
            </li>
        </ul>

        <Empty v-if="couponList.length == 0" title="还没有领取优惠券哦~" />

        <el-pagination v-if="total>perPage" @current-change="pageChange" :current-page="currPage" :page-size="perPage"
                :total="total" layout="total, prev, pager, next, jumper" background>
        </el-pagination>
    </div>
</template>

<script>
import titleText from '@/components/titleText'
import Empty from '@/components/empty'

export default {
    name: 'MyCoupon',
    components: {
        titleText,
        Empty
    },

    data() {
        return {
            couponList: [],
            currPage: 1,
            perPage: 20,
            total: 0
        };
    },

    created() {
        this.getCoupon()
    },

    methods: {
        getCoupon(){
            this.$https.get(this.$api.getCoupon,{
                memberId: this.$store.state.userInfo.id,
                status: 0,
                offset: (this.currPage - 1) * this.perPage,
                limit: this.perPage
            })
            .then((res) =>{
                this.couponList = res.data.rows
                this.total = res.data.total
                document.body.scrollIntoView()
            })
        },

        pageChange(val) {
            if(this.currPage != val){
                this.currPage = val
                this.getCoupon()
            }  
        }
    }
}
</script>
<style lang='scss' scoped>
    .coupon-item{
        width: 277px;
        height: 110px;
        margin: 0 30px 30px 0;
        background: url(../../assets/images/coupon.png);
        &:nth-child(4n){
            margin-right: 0;
        }
        .coupon-item-l{
            width: 94px;
            text-align: center;
            margin-right: 15px;
        }
    }
</style>